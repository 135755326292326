import { Link } from "react-router-dom";
import Season from "./components/Season/Season";

const Schedule = () => {
  return (
    <div className="flex align-center column">
      <Season
        season={"Spring 2025"}
        months={["March", "April", "May"]}
        days={["8-9, 22-23, 29", "5-6, 26", "3-4"]}
      />

      <Link to={"/reservation"} className="schedule-signup-btn">
        Reserve
      </Link>
    </div>
  );
};

export default Schedule;
